<template>
	<div id="app" class="sticky-footer sticky-footer--highlight sticky-footer--backdrop">
		<PrivacyInformation />
    <GMConfirmations class="absolute whitespace-pre" style="z-index: 99999;"/>
    <GMNotifications
        class="absolute z-40 right-10 top-10"
    />
		<div class="sticky-footer__main">
			<TopMenu></TopMenu>
			<Banner></Banner>
			<div class="flex flex-1">
				<slot />
			</div>
		</div>
		<div class="sticky-footer__footer sticky-footer__footer--dark">
			<Footer></Footer>
		</div>
	</div>
</template>

<script>
import Footer from './../components/Footer.vue'
import TopMenu from './../components/TopMenu.vue'
import Banner from '@/components/banner/Switcher.vue'
import PrivacyInformation from '~/components/modal/PrivacyInformation.vue'
import {GMConfirmations, GMNotifications} from "@gm/components";

export default {
	components: {
    GMNotifications,
    GMConfirmations,
		Banner,
		Footer,
		TopMenu,
		PrivacyInformation,
	},
}
</script>

<style lang="scss">
.nuxt-page-wrapper {
	position: relative;
	overflow-x: hidden;
}

.container {
	width: 100%;
}

.slide-left-enter-active,
.slide-left-leave-active,
.slide-right-enter-active,
.slide-right-leave-active {
	position: absolute;
	width: 100vw;
	transition: all 0.3s ease-in-out;
}

/* entering start */
.slide-right-enter,
.slide-left-leave-to {
	opacity: 0;
	transform: translate(-100%, 0);
}

.slide-left-enter,
.slide-right-leave-to {
	opacity: 0;
	transform: translate(100%, 0);
}
</style>
